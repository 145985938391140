import React, { useEffect } from "react";
import { Link } from "gatsby";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Butter from "buttercms";
import { Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SEO from "../components/seo";
import iso27001Icon from "../images/iso_27001@2x.png";
import socIcon from "../images/logo_soc3@2x.png";
import mspartner from "../images/MSpartner.png";
import nistIcon from "../images/security-badge-green@2x.png";
import usEuShieldIcon from "../images/logo_privacyshield@2x.png";
import csaIcon from "../images/logo_csa@2x.png";
import cisIcon from "../images/cis-logo.png";
import { blueGrey, grey, indigo } from "@material-ui/core/colors";

const butter = Butter("09ba7d6ba30c1ad49fcdae19b8bed0ce840ca696");

export default () => {
  const matches = useMediaQuery("(min-width:1350px)");
  const mediumScreen = useMediaQuery("(min-width:750px)");

  const [localState, setLocalState] = React.useState({
    posts: [],
  });

  const getFDAposts = () => {
    butter.post
      .list({
        page: 1,
        page_size: 10,
        exclude_body: "true",
        category_slug: "data-security",
      })
      .then((response) => {
        setLocalState({ posts: response.data.data });
      });
  };

  useEffect(() => {
    getFDAposts();
  }, []);

  return (
    <>
      <SEO
        title="Software Security"
        description="LabLog&#039;perfectly balances user experience and in-depth security in an electronic lab notebook."
      />

      <Box
        display="flex"
        justifyContent="center"
        style={{
          background: "linear-gradient(90deg, #4b6cb7 0%, #182848 100%)",
        }}
        pl={6}
        pr={6}
      >
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box pt={6} pb={4}>
            <Box
              component="h1"
              fontWeight="500"
              color="#ffffff"
              fontSize="h4.fontSize"
            >
              <Box>Trust LabLog to</Box>
              <Box>keep your data secure</Box>
            </Box>
            <Box color="#FFFFFF" fontWeight="250">
              LabLog is independently verified to adhere to
            </Box>
            <Box color="#FFFFFF" fontWeight="250">
              the highest cybersecurity standards.
            </Box>
          </Box>
          {mediumScreen && (
            <Box
              maxWidth="260px"
              position="absolute"
              right="30px"
              overflow="hidden"
            >
              <img src={mspartner} width="100%" />
            </Box>
          )}
        </Box>
      </Box>

      <Container maxWidth="md">
        <Box
          component="p"
          mt={8}
          fontWeight="400"
          color="#3d3d3d"
          fontSize="h6.fontSize"
        >
          We take data security seriously here at LabLog, and we are proud to
          exceed the industry standard when it comes to protecting your
          organization.
        </Box>
        <Box
          textAlign="center"
          component="p"
          mt={3}
          mb={3}
          color="#3d3d3d"
          fontSize="h5.fontSize"
        >
          <strong>Compliance regulations</strong>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <img src={iso27001Icon} height={45} alt="ISO27001 Badge" />
            <br />
            <strong>ISO 27001</strong>
            <br />
            Information Security Management System
            <br />
            <a
              href="https://assets.labnotebook.app/public/ISO%2027001%20Compliance%20Report_LabLog_Redacted%20Version.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Verify now
            </a>
          </Grid>
          <Grid item xs={4}>
            <img src={usEuShieldIcon} height={45} alt="EU Privacy Shield" />
            <br />
            <strong>
              EU/US Privacy Shield
              <br />
              Swiss/US Privacy Shield
            </strong>
            <br />
            Data Privacy Best Practices.
            <br />
            <a
              href="https://help.labnotebook.app/hc/en-us/articles/360053254451-E-U-U-S-and-Swiss-U-S-Privacy-Shield-Policy"
              target="_blank"
              rel="noreferrer"
            >
              View policy
            </a>
          </Grid>
          <Grid item xs={4}>
            <img src={socIcon} height={45} alt="SOC badge" />
            <br />
            <strong>SOC TSP</strong>
            <br />
            Trust Services Principles
            <br />
            <a
              href="https://assets.labnotebook.app/public/SOC%20TSP%20Compliance%20Report_LabLog_Redacted%20Version.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Verify now
            </a>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <img src={iso27001Icon} height={45} alt="ISO27001 Badge" />
            <br />
            <strong>PCI Data Security Standard</strong>
            <br />
            Standard for protecting customer data.
            <br />
            <a
              href="https://assets.labnotebook.app/public/PCI%20DSS%203.2.1%20Compliance%20Report_LabLog_Redacted%20Version.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Verify now
            </a>
          </Grid>
          <Grid item xs={4}>
            <img src={cisIcon} height={45} alt="CIS" />
            <br />
            <strong>Azure CIS 1.1.0</strong>
            <br />
            Center for Internet Security Benchmark
            <br />
            Verification API available to customers
          </Grid>

          <Grid item xs={4}>
            <img src={csaIcon} height={45} alt="CSA Badge" />
            <br />
            <strong>CSA</strong>
            <br />
            Cloud Security Alliance
            <br />
            <a
              href="https://cloudsecurityalliance.org/star/registry/lablog/"
              target="_blank"
              rel="noreferrer"
            >
              Verify now
            </a>
          </Grid>
        </Grid>

        <Box mt={3.5} mb={1} textAlign="center">
          UK Information Commissioner's Office (ICO) Registration Number:&nbsp;
          <a
            href="https://labnotebook.app/blog/lablog-registered-data-controllers-with-the-ico"
            target="_blank"
            rel="noreferrer"
          >
            ZB105641
          </a>
        </Box>

        <Container
          style={{
            marginTop: "70px",
            padding: "40px",
            backgroundColor: grey[100],
          }}
        >
          <Box component="p" m={1} color="#3d3d3d" fontSize="h6.fontSize">
            <strong>Privacy and Data Security</strong>
            <br />
            At LabLog we utilize independent third-party verification to enure
            that we adhere to the privacy and data security requirements of{" "}
            <a
              rel="noopener noreferrer"
              href="https://www.fda.gov/regulatory-information/search-fda-guidance-documents/part-11-electronic-records-electronic-signatures-scope-and-application"
              target="_blank"
            >
              FDA part 11
            </a>{" "}
            and{" "}
            <a
              href="https://www.iso.org/isoiec-27001-information-security.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              ISO 27001
            </a>
            .
            <br />
            <br />
            Our team&apos;s quality system is in alignment with ISO 9001:2015
            and ISO 90003:2018 &quot;SOFTWARE ENGINEERING -- GUIDELINES FOR THE
            APPLICATION OF ISO 9001:2015 TO COMPUTER SOFTWARE&quot;.
          </Box>
        </Container>
        <div
          style={{
            zIndex: "1000",
            position: "relative",
            margin: "auto",
            width: "0",
            height: "0",
            borderLeft: "20px solid transparent",
            borderRight: "20px solid transparent",
            borderTop: `30px solid ${grey[100]}`,
          }}
        />
        <Container
          style={{
            marginTop: "-30px",
            padding: "40px",
            backgroundColor: indigo[50],
          }}
        >
          <Box component="p" m={1} color="#3d3d3d" fontSize="h6.fontSize">
            <strong>Software validation</strong>
            <br />
            We can provide the following documents for clients that require
            vendor software validation as part of their purchasing process:
            <br />
            - Third-party audit reports
            <br />
            - QMS related documents and SOPs
            <br />
            - Software Development Lifecycle (SDLC) SOP
            <br />
            - Gap analysis and test cases
            <br />
            - Version controlled user guide
            <br />
            - Disaster Recovery: Backup and Recovery SOP
            <br />
            - System Access Control SOP
            <br />
            <br />
            In addition, our experienced regulatory affairs team can provide
            support to our small to medium business clients.
          </Box>
        </Container>
        <div
          style={{
            zIndex: "1000",
            position: "relative",
            margin: "auto",
            width: "0",
            height: "0",
            borderLeft: "20px solid transparent",
            borderRight: "20px solid transparent",
            borderTop: `30px solid ${indigo[50]}`,
          }}
        />
        <Container
          style={{
            marginTop: "-30px",
            padding: "40px",
            backgroundColor: grey[100],
          }}
        >
          <Box component="p" m={1} color="#3d3d3d" fontSize="h6.fontSize">
            <strong>Backup and Recovery</strong>
            <br />
            Our geo-replication approach stores all data on two independent
            geographical locations. One is at a primary data center in the
            central US and a secondary one is in the eastern US. This protects
            against hardware failures, network or power outages, and natural
            disasters.
            <br />
            <br />
            Request a <Link to="/request-demo">personalized demo</Link> or{" "}
            <Link to="/request-whitepaper">download our whitepaper</Link> today
            to learn more about LabLog&apos;s privacy and data security
            features.
          </Box>
        </Container>
        <div
          style={{
            zIndex: "1000",
            position: "relative",
            margin: "auto",
            width: "0",
            height: "0",
            borderLeft: "20px solid transparent",
            borderRight: "20px solid transparent",
            borderTop: `30px solid ${grey[100]}`,
          }}
        />
        <Container
          style={{
            marginTop: "-30px",
            padding: "40px",
            backgroundColor: indigo[50],
          }}
        >
          <Box component="p" m={1} color="#3d3d3d" fontSize="h6.fontSize">
            <strong>User Access Controls</strong>
            <br />
            LabLog allows team managers and system administrators to define and
            assign specific roles to team members. LabLog&apos;s team management
            features are in compliance with FDA part 11 and they are designed to
            secure all activity on our electronic labnotebook platform.
          </Box>
        </Container>
        <div
          style={{
            zIndex: "1000",
            position: "relative",
            margin: "auto",
            width: "0",
            height: "0",
            borderLeft: "20px solid transparent",
            borderRight: "20px solid transparent",
            borderTop: `30px solid ${indigo[50]}`,
          }}
        />
        <Container
          style={{
            marginTop: "-30px",
            padding: "40px",
            backgroundColor: grey[100],
          }}
        >
          <Box component="p" m={1} color="#3d3d3d" fontSize="h6.fontSize">
            <strong>Guaranteed Availability</strong>
            <br />
            LabLog is powered by fault-tolerant architecture. Our cloud
            computing infrastructure uses redundant databases, file storage, and
            virtual instances to guarantee availability. We host all our data in
            the United States.
          </Box>
        </Container>
        <Box fontWeight="500" fontSize="0.95em" mt={4} mb={2}>
          More information:
        </Box>
        {localState.posts.map((post) => (
          <Box key={post.url} mb={1.5}>
            &bull;&nbsp;
            <a href={post.url}>{post.title}</a>
          </Box>
        ))}
      </Container>
    </>
  );
};
